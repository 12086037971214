import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Location } from '@commonTuna/react/objects/BaseLocation';

interface Props {
	areas: Array<Location>;
	className?: string;
}

export const BottomAreas: React.FC<Props> = ({ areas, className }) => {
	const { i18n, t } = useTranslation();

	return <div className={`bottom-areas ${className}`}>
		<div className="container">
			<h4>{t('site.Service Areas')}</h4>
			<ul className="bottom-areas__list">
				{areas.map((item, index) => <li key={index}>
					{i18n.language === 'es' ? item.nameEs : item.nameEn}
				</li>)}
			</ul>
		</div>
	</div>;
};
