import * as React from 'react';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import { ReviewCounters } from '@app/objects/ReviewCounters';

export const ReviewCountersComponent: React.FC<{counters: ReviewCounters}> = ({ counters }) => {
	return <div className="review-counters">
		<div className="review-counters__item">
			<ImageLazy src="/images/reviews/google.png" alt="google" width={119} height={49} />
			<div className="review-counters__item-text">
				<span>
					Rating:
					{counters.averageGoogle.toFixed(1)}
				</span>
				{' '}
				|
				<span>
					Reviews:
					{counters.google}
				</span>
			</div>
		</div>
		<div className="review-counters__item">
			<ImageLazy src="/images/reviews/facebook.png" alt="facebook" width={111} height={48} />
			<div className="review-counters__item-text">
				<span>
					Rating:
					{counters.averageFacebook.toFixed(1)}
				</span>
				{' '}
				|
				<span>
					Reviews:
					{counters.facebook}
				</span>
			</div>
		</div>
		<div className="review-counters__item">
			<ImageLazy src="/images/reviews/realself.png" alt="realself" width={130} height={48} />
			<div className="review-counters__item-text">
				<span>Rating: 4.8</span>
				{' '}
				|
				<span>Reviews: 122</span>
			</div>
		</div>
	</div>;
};
