import React from 'react';

import Slider from 'react-slick-adaptive-height-fixed';

const SliderWithObserver = React.forwardRef<any, any>(({ className = '', sliderProps, children }, sliderRef) => {
	const [show, setShow] = React.useState(false);
	const ref = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (ref.current) {
			const intersectionObserver = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					setShow(true);
				}
			}, {
				rootMargin: '50px',
				threshold: 0.01,
			});

			intersectionObserver.observe(ref.current);
			return () => intersectionObserver.disconnect();
		}
	}, [ref.current]);

	return <div className={className} ref={ref}>
		{show ? <Slider {...sliderProps} ref={sliderRef}>
			{children}
		</Slider> : null}
	</div>;
});

export default SliderWithObserver;
