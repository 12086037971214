import React from 'react';

interface OnResizeParams {
	width: number;
	height: number;
	element: Element | undefined | null;
	ref: React.RefObject<HTMLDivElement>;
}

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	onResize: (params: OnResizeParams) => void;
	getContainer?: (element: HTMLElement) => Element | undefined | null;
}

const ResizeObserverContainer: React.FC<Props> = (props) => {
	const {
		onResize,
		children,
		getContainer = (element) => element,
		...other
	} = props;
	const ref = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (ref.current) {
			const element = getContainer(ref.current);
			const resizeObserver = new ResizeObserver((entries) => {
				typeof window !== 'undefined' && window.requestAnimationFrame(() => {
					entries.forEach((entry) => {
						if (entry.contentBoxSize) {
							const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;

							const width = (contentBoxSize?.inlineSize || entry.contentRect?.width);
							const height = entry.contentRect?.height;
							if (ref.current) {
								const element = getContainer(ref.current);
								onResize({
									width,
									height,
									element,
									ref,
								});
							}
						}
					});
				});
			});

			if (element) {
				resizeObserver.observe(element);
			}

			return () => {
				ref.current && element && resizeObserver.unobserve(element);
			};
		}
	}, [onResize]);

	return <div ref={ref} {...other}>
		{children}
	</div>;
};

export default ResizeObserverContainer;
