import React from 'react';

import { dateFormat } from '@common/typescript/Utils';

import { Review } from '@commonTuna/react/objects/Review';

import Rate from '@app/components/UI/Rate/Rate';
import SliderWithObserver from '@app/components/UI/SliderWithObserver/SliderWithObserver';

interface Props {
	reviews: Array<Review>;
}

const HomeReviews: React.FC<Props> = ({ reviews }) => {
	const [currentSlide, setCurrentSlide] = React.useState<number>(0);
	const slider = React.createRef<any>();
	const goNext = () => {
		if (slider.current) slider.current.slickNext();
	};

	const goPrev = () => {
		if (slider.current) slider.current.slickPrev();
	};

	const slickSettings = React.useMemo(() => ({
		centerMode: true,
		slidesToShow: 1,
		className: 'home-page-testimonials-slider mobile-hidden',
		centerPadding: '300px',
		arrows: false,
		dots: false,
		beforeChange: (current, next) => setCurrentSlide(next === -1 ? 0 : next),
		responsive: [
			{
				breakpoint: 1400,
				settings: {
					centerPadding: '230px',
				},
			},
			{
				breakpoint: 1200,
				settings: {
					centerPadding: '150px',
				},
			},
			{
				breakpoint: 1100,
				settings: {
					centerMode: false,
					adaptiveHeight: true,
				},
			},
		],
	}), []);

	return <SliderWithObserver sliderProps={slickSettings} ref={slider}>
		{reviews.map((item) => {
			return <div className="testimonials-slider-item-container container" key={item.id}>
				<div className="container testimonials-slider-item">
					<div className="container testimonials-slider-item__inner" style={{ width: 'calc(100% - 40px)' }}>
						<div className="testimonials-slider-item__nav_prev testimonials-slider-item__nav" onClick={goPrev}>
							<i className="fa fa-caret-left" aria-hidden="true" />
						</div>
						<ul className="testimonials-slider-item__pagination">
							{reviews.map((item, index) =>
								<li
									onClick={() => currentSlide !== index && slider.current?.slickGoTo(index)}
									className={currentSlide === index ? 'active' : ''}
									key={item.id}
								>
									{index >= 9 ? '' : '0'}
									{index + 1}
								</li>)}
						</ul>
						<div className="testimonials-slider-item__inner-right">
							<div className="testimonials-slider-item__rate">
								<Rate value={item.rate} className="ml10" />
							</div>
							<div className="testimonials-slider-item__text">
								{item.textEn}
							</div>
							<div className="testimonials-slider-item__name">
								-
								{' '}
								{item.author}
								{item.time > 0 && ` - ${dateFormat(item.time, { year: 'numeric', month: 'long', day: 'numeric' })}`}
							</div>
						</div>
						<div className="testimonials-slider-item__nav_next testimonials-slider-item__nav" onClick={goNext}>
							<i className="fa fa-caret-right" aria-hidden="true" />
						</div>
					</div>
				</div>
			</div>;
		})}
	</SliderWithObserver>;
};

export default HomeReviews;
