import React from 'react';
import Slider from 'react-slick-adaptive-height-fixed';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';
import LinkWithLang from '@common/react/components/UI/lang/LinkWithLang/LinkWithLang';

import { Honor } from '@app/objects/Honor';

const giftsSlickSettings = {
	slidesToShow: 5,
	className: 'home-page-gifts',
	arrows: true,
	dots: false,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 4,
			},
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 500,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};

const Honors: React.FC<{ honors: Array<Honor> }> = ({ honors }) => {
	return honors.length > 0 ? <div className="container mobile-hidden">
		<Slider {...giftsSlickSettings}>
			{
				honors.map((item, index) =>
					<div key={index} className="home-page-gifts__item">
						{item.link ? <LinkWithLang aria-label={item.titleEn} to={item.link}>
							<ImageLazy src={item.avatar} alt={item.titleEn || `honor ${index}`} />
						</LinkWithLang> : <span>
							<ImageLazy src={item.avatar} alt={item.titleEn || `honor ${index}`} />
						</span>}
					</div>)
			}
		</Slider>
	</div> : null;
};

export default Honors;
